<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs">
          <template v-slot:divider>
            <v-icon icon="mdi-chevron-right"></v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-col class="pa-0">
        <v-card>
          <div class="d-flex flex-wrap">
            <v-avatar size="110" class="ma-4">
              <v-img
                @contextmenu.prevent
                :src="this.store?.store_image"
                :alt="this.store?.store_name"
                :lazy-src="this.store?.store_image"
              ></v-img>
            </v-avatar>

            <div class="d-flex flex-grow-1 flex-column justify-center ma-4">
              <div class="text-h5 text-primary font-weight-bold">
                {{ store?.store_name }}
              </div>

              <div>
                <v-btn
                  :href="`mailto:${store?.user_email}`"
                  prepend-icon="mdi-email"
                  class="text-none my-2 mr-2"
                  color="secondary-container"
                  >{{ store?.user_email }}</v-btn
                >
                <v-btn
                  @click="dialogStore.show = true"
                  prepend-icon="mdi-share-variant"
                  class="text-none my-2 mr-2"
                  color="secondary-container"
                  >Bagikan</v-btn
                >
              </div>
            </div>

            <div class="d-flex flex-grow-1 justify-space-around ma-4">
              <div class="d-flex flex-column justify-center">
                <div class="d-flex justify-center align-bottom">
                  <v-icon icon="mdi-package" color="secondary-60"></v-icon>
                  <span class="text-subtitle-1 font-weight-bold text-primary">{{
                    store?.product_count
                  }}</span>
                </div>
                <p class="text-subtitle-2 font-weight-medium">Total Produk</p>
              </div>

              <v-divider
                vertical
                inset
                class="border-dashed"
                thickness="2"
              ></v-divider>

              <div class="d-flex flex-column justify-center">
                <div class="d-flex justify-center align-bottom">
                  <v-icon
                    icon="mdi-ticket-confirmation"
                    color="secondary-60"
                  ></v-icon>
                  <span class="text-subtitle-1 font-weight-bold text-primary">{{
                    store?.ticket_count
                  }}</span>
                </div>
                <p class="text-subtitle-2 font-weight-medium">
                  Total Tiket Terjual
                </p>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- 
    <div class="d-flex flex-wrap justify-end align-top">
      <v-select
        :items="etalase"
        v-model="etalaseId"
        item-value="productgroup_id"
        item-title="productgroup_name"
        label="Etalase"
        variant="outlined"
        density="comfortable"
      >
      </v-select>

      <v-select
        variant="outlined"
        v-model="categorySelected"
        :items="filterCategory"
        item-title="productcategory_name"
        item-value="productcategory_id"
        hide-details
        density="comfortable"
      ></v-select>

      <v-btn
        @click="dialog.state = true"
        variant="outlined"
        prepend-icon="mdi-tune"
        height="45"
        class="text-none"
        >Filter</v-btn
      >

      <v-select
        v-model="sortbySelected"
        :items="filterSortby"
        prepend-inner-icon="mdi-sort-ascending"
        hide-details
        label="Urut berdasarkan"
        variant="outlined"
        item-title="name"
        item-value="state"
        density="comfortable"
      ></v-select>
    </div> -->

    <v-row justify="center">
      <v-col cols="3" class="ps-0 d-none d-md-block">
        <v-card class="sticky" rounded="lg">
          <v-card-title class="text-primary">Kategori Etalase</v-card-title>
          <v-list
            max-height="400"
            v-model:selected="etalaseId"
            :items="etalase"
            item-value="productgroup_id"
            item-title="productgroup_name"
            class="mx-2"
            active-class="border-active text-primary"
          >
          </v-list>
        </v-card>
      </v-col>

      <v-col cols="12" xxl="9" xl="9" lg="9" md="9" class="px-0">
        <v-row justify="end" align="top">
          <v-col
            class="d-flex d-md-none"
            cols="12"
            xxl="3"
            xl="3"
            lg="3"
            md="4"
            sm="6"
          >
            <v-select
              :items="etalase"
              v-model="etalaseId"
              item-value="productgroup_id"
              item-title="productgroup_name"
              label="Etalase"
              hide-details
              variant="outlined"
              density="comfortable"
            >
            </v-select>
          </v-col>
          <v-col cols="12" xxl="3" xl="3" lg="3" md="4" sm="6">
            <v-select
              variant="outlined"
              v-model="categorySelected"
              :items="filterCategory"
              item-title="productcategory_name"
              item-value="productcategory_id"
              hide-details
              density="comfortable"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            xxl="2"
            xl="2"
            lg="2"
            md="4"
            sm="6"
            class="d-flex justify-center"
          >
            <v-btn
              block
              @click="dialog.state = true"
              variant="outlined"
              prepend-icon="mdi-tune"
              height="45"
              class="text-none"
              >Filter</v-btn
            >
          </v-col>
          <v-col cols="12" xxl="3" xl="3" lg="3" md="4" sm="6">
            <v-select
              v-model="sortbySelected"
              :items="filterSortby"
              prepend-inner-icon="mdi-sort-ascending"
              hide-details
              label="Urut berdasarkan"
              variant="outlined"
              item-title="name"
              item-value="state"
              density="comfortable"
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="isLoading">
          <v-spacer></v-spacer>
          <v-col>
            <div class="text-center">
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>

        <v-row v-else-if="totalPage < 1">
          <no-product></no-product>
        </v-row>

        <v-row justify="start" v-else>
          <template v-for="(product, index) in this.products" :key="index">
            <v-col class="mx-auto" cols="auto">
              <event-card :product="product"></event-card>
              <non-event-card :product="product"></non-event-card>
              <upcoming-card v-if="false" :product="product"></upcoming-card>
              <fun-card v-if="false" :product="product"></fun-card>
              <workshop-card v-if="false" :product="product"></workshop-card>
            </v-col>
          </template>
        </v-row>

        <v-row>
          <v-spacer></v-spacer>
          <v-col>
            <v-pagination v-model="page" :length="totalPage"></v-pagination>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-row>

    <v-snackbar snackbar color="white" v-model="snackbar.status" rounded="pill">
      <div class="text-black">
        {{ snackbar.text }}
      </div>

      <template v-slot:actions>
        <v-btn
          color="pink"
          rounded="pill"
          variant="text"
          @click="snackbar.status = false"
        >
          Tutup
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>

  <filter-product
    :filterEventDate="false"
    :locationData="locationData"
    v-model:dialog="dialog.state"
    @filtering="filtering"
  ></filter-product>

  <v-dialog width="auto" v-model="dialogStore.show">
    <v-card width="500">
      <v-card-item>
        <v-card-title class="text-center"> Bagikan Toko </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-slide-group show-arrows>
          <v-slide-group-item>
            <div class="mx-3 d-flex flex-column align-center">
              <v-btn
                @click="share('wa')"
                color="green"
                icon="mdi:mdi-whatsapp"
                variant="outlined"
              >
              </v-btn>
              <div class="text-caption font-weight-medium">Whatsapp</div>
            </div>
          </v-slide-group-item>
          <v-slide-group-item>
            <div class="mx-3 d-flex flex-column align-center">
              <v-btn
                @click="share('telegram')"
                color="blue"
                icon="fa:fa-telegram fa-brands"
                variant="outlined"
              >
              </v-btn>
              <div class="text-caption font-weight-medium">Telegram</div>
            </div>
          </v-slide-group-item>
          <v-slide-group-item>
            <div class="mx-3 d-flex flex-column align-center">
              <v-btn
                @click="copyUrlStore"
                icon="mdi-content-copy"
                variant="outlined"
              >
              </v-btn>
              <div id="dialog-share"></div>
              <div class="text-caption font-weight-medium">Salin</div>
            </div>
          </v-slide-group-item>
        </v-slide-group>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import NoProduct from "@/components/Negative/NoProduct.vue";
import { utcToLocale } from "@/utils/dateFormatter";
import EventCard from "@/components/Cards/EventCard.vue";
import FunCard from "@/components/Cards/FunCard.vue";
import WorkshopCard from "@/components/Cards/WorkshopCard.vue";
import UpcomingCard from "@/components/Cards/UpcomingCard.vue";
import NonEventCard from "@/components/Cards/NonEventCard.vue";
import FilterProduct from "@/components/FilterProduct.vue";
import { CATEGORY } from "@/config/category";

const tabData = {
  BERANDA: 1,
  PRODUK: 2,
};

export default {
  name: "StoreView",

  components: {
    EventCard,
    FunCard,
    WorkshopCard,
    UpcomingCard,
    NonEventCard,
    NoProduct,
    FilterProduct,
  },

  computed: {
    getEtalaseID() {
      return this.etalaseId[0];
    },
  },

  data() {
    return {
      dialogStore: {
        show: false,
      },
      rules: {
        filterPrice: [
          (value) => {
            return (
              !value[0] ||
              !value[1] ||
              parseInt(value[0]) <= parseInt(value[1]) ||
              "Harga minimum lebih besar dari maximum"
            );
          },
        ],
      },
      filter: {
        category: -1,
        date: null,
        priceLow: null,
        priceHigh: null,
        city: "",
      },
      store: null,
      rating: 3,
      tab: tabData.PRODUK,
      tabData,
      snackbar: {
        status: false,
        text: null,
      },
      isLoading: true,
      dialog: {
        state: false,
        filter: {
          category: null,
          date: null,
          price: [null, null],
          location: [],
        },
      },
      min: 0,
      max: 100_000_000,
      locationData: [
        "Jakarta",
        "Bekasi",
        "Surabaya",
        "Medan",
        "Bandung",
        "Depok",
        "Bali",
        "Palembang",
        "Yogyakarta",
        "Tangerang",
        "Makassar",
        "Balikpapan",
        "Semarang",
        "Cikarang",
        "Padang",
      ],
      products: [],
      token: null,
      page: 1,
      itemsPerPage: 12,
      totalPage: 1,
      etalase: [],
      breadcrumbs: [
        {
          title: "Beranda",
          href: "/",
        },
      ],
      filterCategory: [],
      categorySelected: 0,
      filterSortby: [
        { name: "Terbaru", state: { order: "desc" } },
        { name: "Harga Terendah", state: { sort: "price", order: "asc" } },
        { name: "Harga Tertinggi", state: { sort: "price", order: "desc" } },
      ],
      sortbySelected: { order: "desc" },
      etalaseId: [-1],
      query: "",
    };
  },

  async created() {
    this.axios.get("/product/own/city").then((response) => {
      this.locationData = response.data.response.map(
        (city) => city.locationcity_name
      );
    });

    const categories = await this.axios.get("/product/categories");

    const filteredCategories = categories.data.response.filter((category) =>
      [
        CATEGORY.TAMAN_HIBURAN,
        CATEGORY.KONSER,
        CATEGORY.HEALING,
        CATEGORY.RACE,
      ].includes(category.productcategory_id)
    );

    this.filterCategory = [
      {
        productcategory_id: 0,
        productcategory_name: "Semua Kategori",
      },
      ...filteredCategories,
    ];

    this.store = (
      await this.axios.get(`user/${this.$route.params.user_id}/store`)
    )?.data?.response;

    this.breadcrumbs.push({
      title: this.store?.store_name,
      disabled: false,
      color: "primary",
    });

    const etalase = (
      await this.axios.get(
        `product/seller/store/${this.$route.params.store_id}/group`
      )
    )?.data?.response;

    this.etalase = [
      {
        productgroup_id: -1,
        productgroup_name: "Semua Produk",
        productgroup_image: "test",
      },
      ...etalase,
    ];

    const sort = this.sortbySelected?.sort;
    const order = this.sortbySelected?.order;

    this.getProduct({
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      sort: sort,
      order: order,
    });
  },

  watch: {
    "dialog.filter.price": {
      deep: true,
      async handler() {
        this.$refs.filterPrice.validate();
      },
    },
    page: {
      handler() {
        this.refreshProduct();
      },
    },
    sortbySelected: {
      deep: true,
      handler() {
        this.page = 1;
        this.refreshProduct();
      },
    },

    categorySelected: {
      handler() {
        this.page = 1;
        this.refreshProduct();
      },
    },
    getEtalaseID: {
      deep: true,
      handler() {
        this.page = 1;
        this.refreshProduct();
      },
    },
  },

  methods: {
    share(platform) {
      const url = window.location.origin + this.$route.fullPath;
      const storeName = this.store?.store_name;
      let path = "";

      switch (platform) {
        case "wa": {
          path = `https://api.whatsapp.com/send?text=${storeName} ${url}`;
          break;
        }
        case "telegram": {
          path = `https://t.me/share/url?url=${url}&text=${storeName}`;
          break;
        }
      }

      window.open(path, "_blank");
    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";
      textArea.style.display = "hidden";

      document.getElementById("dialog-share").appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
        this.showSnackbar(`Link store berhasil di salin ke clipboard`);
      } catch (err) {
        this.showSnackbar(`Gagal menyalin data`);
      }

      document.getElementById("dialog-share").removeChild(textArea);
    },
    async copyUrlStore() {
      const url = `${window.location.origin}${this.$route.fullPath}`;
      if (navigator.clipboard) {
        await navigator.clipboard
          .writeText(url)
          .then(() => {
            this.showSnackbar(`Link store berhasil di salin ke clipboard`);
          })
          .catch(() => {
            this.showSnackbar(`Gagal menyalin data`);
          });
      } else {
        {
          this.fallbackCopyTextToClipboard(url);
        }
      }
    },
    filtering(newValue) {
      if (newValue.price.length) {
        this.filter.priceLow = newValue.price[0];
        this.filter.priceHigh = newValue.price[1];
      }
      this.filter.city = newValue.location.join(",");
      this.filter.startDate = newValue.startDate;
      this.filter.endDate = newValue.endDate;
      this.page = 1;
      this.refreshProduct();
    },

    resetFilter() {
      this.dialog.filter.category = null;
      this.dialog.filter.date = null;
      this.dialog.filter.location = [];
      this.dialog.filter.price = [null, null];
      this.filtering();
    },
    refreshProduct() {
      const sort = this.sortbySelected?.sort;
      const order = this.sortbySelected?.order;
      this.getProduct({
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        sort: sort,
        order: order,
        etalase: this.getEtalaseID,
        category: this.categorySelected,
        priceHigh: this.filter.priceHigh || null,
        priceLow: this.filter.priceLow || null,
        city: this.filter.city || null,
      });
    },
    async getProduct({
      page,
      itemsPerPage,
      sort,
      order,
      etalase,
      category,
      city,
      priceHigh,
      priceLow,
    }) {
      this.page = page;
      this.itemsPerPage = itemsPerPage;

      this.isLoading = true;
      this.axios
        .get(`product/store/${this.$route.params.store_id}`, {
          params: {
            pageSize: itemsPerPage,
            page: page,
            etalase: etalase == -1 ? null : etalase,
            category: category || null,
            sortby: sort,
            order: order,
            city: city,
            priceHigh: priceHigh || null,
            priceLow: priceLow || null,
          },
        })
        .then((response) => {
          const data = response.data.response;
          const products = data.products.map((product) => {
            product.name = product.product_name;
            product.store_name = product.store?.store_name;

            return product;
          });

          const soldOut = products.filter((product) => {
            if (product.quantity <= 0 && product.is_limited) {
              return product;
            }
          });
          const ready = products.filter((product) => {
            if (product.quantity > 0 || !product.is_limited) {
              return product;
            }
          });

          this.products = [...ready, ...soldOut];
          this.totalPage = Math.ceil(data.count / this.itemsPerPage);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        });
    },

    getYear(timestamp) {
      const date = new Date(timestamp);
      return date.getFullYear();
    },

    convertDate(isoDate) {
      return utcToLocale(isoDate, "DD-MM-YYYY");
    },

    kFormatter(number) {
      return Math.abs(number) > 999
        ? Math.sign(number) * (Math.abs(number) / 1000).toFixed(1) + "k"
        : Math.sign(number) * Math.abs(number);
    },

    toDetail(id) {
      this.$router.push("/detail/" + id);
    },

    showSnackbar(text) {
      this.snackbar.status = true;
      this.snackbar.text = text;
    },
  },
};
</script>

<style scoped>
.primary-e-tix {
  background: rgb(var(--v-theme-primary));
}

.border-active {
  border-left: 2px rgb(var(--v-theme-primary)) solid;
}

.primary-outline-e-tix {
  border-color: rgb(var(--v-theme-primary));
}
</style>
